<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'deposit' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          ฝากเครดิต
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5 class="font-weight-bolder m-0">
          ฝากเหรียญ USDT
        </h5>
      </div>
      <div class="text-center">
        <small
          class="text-danger"
        ><u>"กรุณาตรวจเช็คข้อมูลให้ถูกต้องก่อนทำรายการ"</u></small><br>
        <small
          class="text-yellow"
        >*ระบบจะเติมเครดิตเกมอัตโนมัติ ภายในเวลา 1-5 นาที</small>
      </div>

      <div v-if="User.crypto_accno">
        <div
          style="margin: auto"
          class="
            text-white text-center
            justify-content-center
            box-deposit
            p-1
            border-10
          "
        >
          <div
            class="fs-16 font-weight-600 text-color"
          >
            <span> เติมเงินขั้นต่ำ 1 USDT </span>
          </div>
        </div>

        <div class="text-center">
          <div class="card-qr">
            <qr-code
              :text="agent.crypto_address"
              size="120"
              error-level="L"
            />
          </div>

          <div class="mt-50">
            <small class="text-muted">
              Send only USDT to this deposit address.
            </small>
          </div>
        </div>
      </div>

      <div>
        <div class="mt-1">
          <small class="text-muted">My Address</small>
          <div class="d-flex justify-content-between align-items-center">
            <p
              v-if="edit"
              class="mb-0 text-warning"
            >
              {{ User.crypto_accno }}
            </p>

            <input
              v-else
              id="fname"
              v-model="User.crypto_accno"
              type="text"
              name="fname"
              class="inputs mr-1"
              :disabled="edit"
              placeholder="กรุณากรอก Address ของฉัน"
              @change="saveQrcode()"
            >

            <i
              class="fa fa-pen text-muted"
              @click="editAddress()"
            />
          </div>
          <p
            v-if="!User.crypto_accno"
            class="mb-0 text-letf cursor-pointer"
            @click="editAddress()"
          >
            + เพิ่ม Address ของฉัน
          </p>
        </div>
      </div>

      <div v-if="User.crypto_accno">
        <div>
          <div class="my-1">
            <small class="text-muted">Network</small>
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0">
                BNB Smart Chain (BEP20)
              </p>

              <i class="fas fa-exchange text-muted" />
            </div>
          </div>

          <div class="my-1">
            <small class="text-muted">USDT Deposit Address</small>
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0">
                {{ agent.crypto_address }}
              </p>

              <i
                v-clipboard:copy="agent.crypto_address"
                class="fas fa-copy text-muted cursor-pointer ml-1"
                @click="copy()"
              />
            </div>
          </div>

        </div>

        <div class="card-noti p-2">
          <div class="d-flex justify-content-between align-items-center my-25">
            <small class="text-muted">Min deposit</small>

            <small>>1.00 USDT</small>
          </div>

          <div class="d-flex justify-content-between align-items-center my-25">
            <small class="text-muted">Exp arrival</small>

            <small>15 network confirmations</small>
          </div>

          <div class="d-flex justify-content-between align-items-center my-25">
            <small class="text-muted">Exp unlock</small>

            <small>15 network confirmations</small>
          </div>
        </div>

        <button
          class="btn mt-1 btn-block router-link-active btn-primary"
          @click="saveQrcode()"
        >
          <i class="fal fa-download mr-25" /> บันทึก QR Code
        </button>
      </div>
    </div>

    <div class="text-left text-head-hisotry mt-2">
      ประวัติการเติมเครดิต [รายการล่าสุด 5 รายการ]
    </div>

    <div
      class="
          w-100
          border-solid
          rounded-lg
          text-color
          bg-table
          border-10
          table-responsive
        "
    >
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-sm"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th
              role="columnheader"
              scope="col"
              aria-colindex="1"
              class="small text-left pl-1"
            >
              <div>วันที่/เวลา</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="2"
              class="small text-center pr-1"
            >
              <div>จำนวนเงิน</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="3"
              class="small text-center pr-1"
            >
              <div>สถานะ</div>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="depData.length > 0"
          role="rowgroup"
        >
          <tr
            v-for="(key, index) in depData"
            :key="index._id"
            role="row"
            class="b-table-empty-row"
          >
            {{ withdrawData }}
            <td>{{ key.timestamp }}</td>
            <td class="text-center">
              {{ key.amount }}บาท
            </td>
            <td class="text-center">
              <b-badge
                v-if="key.status === 'success'"
                variant="light-success"
              >
                <small>สำเร็จ</small>
              </b-badge>

              <b-badge
                v-if="key.status === 'waiting'"
                variant="light-warning"
              >
                <small>รอดำเนินการ</small>
              </b-badge>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            role="row"
            class="b-table-empty-row"
          >
            <td
              colspan="3"
              role="cell"
            >
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-color text-center pt-2 pb-2 fs-14">
                  ไม่พบข้อมูล
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <br><br><br><br><br>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { BBadge } from 'bootstrap-vue'
// import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    BBadge,
    // QrcodeVue,
  },
  props: {
    // eslint-disable-next-line
      UserProfile: Object,
  },
  data() {
    return {
      edit: true,
      data: [],
      agent: {},
      agents: {
        my_crypto_accno: '',
        crypto_accno: '0x123oqwe123qwe45507m560aadsasd',
      },
      balance: '',
      amount: '',
      listData: '',
      depData: '',
      withdrawData: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      wto: null,
    }
  },
  mounted() {
    this.GetAgentData()
    this.getdeposit(this.userData)
    this.getUser(this.userData)
  },
  methods: {
    editAddress() {
      this.edit = !this.edit
    },
    saveQrcode() {
      this.UpdateCryptoAdd()
    },
    UpdateCryptoAdd() {
      const obj = {
        crypto_accno: this.User.crypto_accno,
      }
      this.$http.post(`/users/update/crypto/${this.userData.userID}`, obj)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: '<h3 style="color: #FFF">บันทึกข้อมูล</h3>',
            showConfirmButton: false,
            timer: 1500,
            background: '#202124',
          }).then(() => {
            this.edit = true
          })
        })
        .catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '<h3 style="color: #FFF">บันทึกข้อมูลไม่สำเร็จ</h3>',
            showConfirmButton: false,
            timer: 1500,
            background: '#202124',
          }).then(() => {
            this.edit = true
          })
        })
    },
    getUser(userData) {
      this.$http
        .get(`/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.User = response.data
          this.getdeposit(this.User.username)
        })
        .catch(error => console.log(error))
    },

    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data)
          this.agent = response.data
          if (!response.data.dip_bank2_accno) {
            // Swall Noti
            this.$swal({
              title: 'แจ้งเตือน',
              text: 'ขณะนี้ระบบฝากกสิกรยังไม่เปิดให้บริการ',
              icon: 'warning',
              confirmButtonText: 'รับทราบ',
            })
          }
        })
        .catch(error => console.log(error))
    },
    getdeposit(username) {
      this.show = true
      this.$http
        .get(`/deposit/username/${username}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.depData = response.data
          let index = 0
          this.depData.forEach(items => {
            this.depData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกเลขบัญชีสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tbody tr {
    padding-left: 5px !important;
  }
  .btn-copy {
    background: linear-gradient(90deg, #494949 0%, #000000 100%);
    color: #fff;
    border: none;
    border-radius: 6px;
    height: 35px;
    padding: 2px 10px;
  }
  .btn-copy:hover {
    box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.2);
  }
  .card-bank {
    min-height: 175px;
    border-radius: 14px;
    /* background-image: url('/bg-bank2.svg'); */
    background: linear-gradient(
        0deg,
        rgba(185, 153, 91, 0.9),
        rgba(221, 204, 151, 0.9),
        rgba(185, 153, 91, 0.9)
      ),
      url("/bg-bank2.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 6px;
    padding: 10px;
  }
  .dark {
    color: #000 !important;
  }
  .header-dark {
    background: linear-gradient(180deg, #626166, #3d3a3d) !important;
    padding: 10px;
  }
  .card-list {
    margin: 0 15px;
    background: #2e2e2e;
    box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
    border-radius: 10px;
    padding: 10px;
  }

  .card {
    border-radius: 14px;
    border: 1px solid rgb(51, 51, 51);
    background: #232323 !important;
    color: rgb(255, 255, 255) !important;
  }
  .text-yellow {
    color: #ffc107 !important;
  }
  .bg-balance {
    background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
    background-repeat: repeat !important;
    background-size: 100% auto !important;
  }
  .btn-withdraw {
    height: 36px;
    background-color: #ffffff27;
    color: #fff !important;
    border: 1px solid #fff;
    font-weight: 700;
    padding: 0 6px;
    border-radius: 2px !important;
  }
  .btn-depo {
    height: 36px;
    background: #fff;
    border: none;
    font-weight: 700;
    padding: 0 6px;
    border-radius: 2px !important;
  }
  .theme-ltobet .book_bank_content .bank-icon .media-icon {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    border-radius: 4px;
    position: relative;
  }
  .btn-orange {
    background-image: linear-gradient(103deg, #0c2574 1%, #341082);
    width: 100%;
    border-radius: 10px !important;
    color: #ffffff !important;
  }
  .box-ac-items {
    padding: 10px;
    border-radius: 5px;
    border: #000 1px solid;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  </style>
